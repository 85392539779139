import $ from "jquery";
$(function () {
  let flagVal = true;
  $(document).on("click", "#headUl li", function () {
    flagVal = false;
    $(this).addClass("active").siblings().removeClass("active");
    const index = $(this).index();
    const distanceH = $(".right dl").eq(index).offset().top - 100;
    $("html,body").animate(
      {
        scrollTop: distanceH,
      },
      function () {
        flagVal = true;
      }
    );
  });
  $(window).scroll(function () {
    const scrollDocTop =
      window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    if (scrollDocTop > 400) {
      $("#headUl").addClass("toUlFixed");
    } else {
      $("#headUl").removeClass("toUlFixed");
    }
    if (flagVal) {
      const winH = $(window).innerHeight() / 4;
      const scrollT = $(window).scrollTop();
      const len = $("#headUl li").length;
      for (let i = 0; i < len; i++) {
        const bannerGap = $(".right dl")[i].offsetTop - scrollT;
        if (bannerGap < winH) {
          $("#headUl li")
            .eq(i)
            .addClass("active")
            .siblings()
            .removeClass("active");
        }
      }
    }
  });
});
